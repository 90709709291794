<template>
    <div ref="chart" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'SortChart',
    props: {
        data: {
            default: () => []
        },
        title: {
            type: String,
            default: 'titre'
        },
        description: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '100%'
        },
        width: {
            type: String,
            default: '100%'
        }
    },
    mounted() {
        this.initChart();
    },
    methods: {
        initChart() {
            const chartDom = this.$refs.chart;
            const myChart = echarts.init(chartDom);

            const option = {
                xAxis: {
                    type: 'category',
                    data: this.data.commercials,
                    axisLabel: { interval: 0, rotate: 30 }
                },
                yAxis: { type: 'value' },
                title: {
                    text: this.title,
                    subtext: this.description,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a}: {b} <br>{c} licences ouvertes'
                },
                sLabel: { interval: 0, rotate: 30 },
                series: [
                    {
                        name: 'Commercial',
                        type: 'bar',
                        data: this.data.values
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        updateChart(newData) {
            const chartDom = this.$refs.chart;
            const myChart = echarts.init(chartDom);

            const option = {
                xAxis: {
                    type: 'category',
                    data: newData.commercials,
                    axisLabel: { interval: 0, rotate: 30 }
                },
                yAxis: { type: 'value' },
                title: {
                    text: this.title,
                    subtext: this.description,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a}: {b} <br>{c}'
                },
                sLabel: { interval: 0, rotate: 30 },
                series: [
                    {
                        name: 'Commercial',
                        type: 'bar',
                        data: newData.values
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    },
    watch: {
        data: {
            handler(newData) {
                this.updateChart(newData);
            },
            deep: true
        }
    }
};
</script>

<style scoped>
/* Vous pouvez ajouter des styles ici si nécessaire */
</style>
