<template>
    <div class="m-0 d-flex flex-wrap align-items-center mt-5" :class="mobile ? 'justify-content-center' : 'justify-content-between'">
        <div
            class="d-flex flex-wrap align-items-center mb-2"
            :class="mobile ? 'justify-content-center' : 'justify-content-between'"
            style="width: 100%"
        >
            <b-form-group :style="mobile ? 'width:100%' : 'width:60%'" class="m-0" label-for="filterInput">
                <b-input-group>
                    <b-form-input v-model.lazy="filter" type="search" id="filterInput" placeholder="Rechercher avec le code du client" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </div>
        <b-table
            :items="clients.data"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="1"
            :per-page="clients.meta.per_page"
            responsive
            hover
            style="vertical-align: middle; text-align: center"
            class="mx-0 p-0"
            :class="mobile ? 'mb-5 mt-0' : 'my-5'"
        >
            <template #cell(group)="row">
                <div v-if="row.item.group" class="d-flex flex-column justify-content-around align-items-center">
                    {{ row.item.holding_info ? row.item.holding_info.name : row.item.name }}
                    <span class="text-muted">( {{ row.item.holding_info ? row.item.holding_info.code : row.item.code }} )</span>
                </div>
            </template>
            <template #cell(Action)="row">
                <router-link
                    :to="{ name: 'client/assign', params: { client: row.item } }"
                    replace
                    v-if="$store.getters.currentUser.role === 'admin'"
                >
                    <button class="btn btn-clean btn-sm svg-icon svg-icon-primary mr-2" v-b-tooltip.hover.top="'Gestion des licences'">
                        <inline-svg src="/media/svg/icons/General/Attachment1.svg" />
                    </button>
                </router-link>
                <router-link :to="{ name: 'classes/view', params: { id: row.item.id } }" replace>
                    <button
                        class="btn btn-icon btn-circle btn-light btn-sm svg-icon svg-icon-primary"
                        v-b-tooltip.hover.top="'Voir les classes'"
                    >
                        <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
                    </button>
                </router-link>
            </template>
        </b-table>
        <div class="d-flex align-items-end" :class="mobile ? 'justify-content-center' : 'justify-content-between'" style="width: 100%">
            <b-col sm="5" md="4">
                <b-pagination v-model="currentPage" :total-rows="clients.meta.total" :per-page="clients.meta.per_page" align="fill" />
            </b-col>
            <div>
                <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                    <li class="page-item active">
                        <a class="page-link bg-primary">Nombre de résultats : {{ this.clients.meta ? this.clients.meta.total : '' }}</a>
                    </li>
                </ul>
            </div>
            <b-form-group
                hidden
                label="Nombre de résultats par page"
                label-for="perPageSelect"
                class="m-0 mr-2"
                :class="mobile ? 'text-center' : ''"
            >
                <b-form-select v-model="clients.meta.per_page" id="perPageSelect" size="sm" :options="pageOptions" />
            </b-form-group>
        </div>
    </div>
</template>

<script>
import { LIST_CLIENT } from '@/core/services/store/api/client.service';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            currentPage: 1,
            perPage: 15,
            pageOptions: [15],
            filter: null,
            isBusy: false,
            sortBy: 'id',
            sortDesc: false,
            fields: [
                { key: 'code', label: 'Code', sortable: true },
                { key: 'name', label: 'Nom', sortable: true },
                { key: 'siret', label: 'Siret', sortable: true },
                { key: 'group', label: 'Établissement', sortable: true },
                { key: 'Action', label: 'Actions', sortable: false }
            ]
        };
    },
    computed: {
        ...mapGetters({ clients: 'getClient' }),
        mobile() {
            return window.screen.availWidth <= 992;
        }
    },
    mounted() {
        this.$store.dispatch(LIST_CLIENT, { page: this.currentPage, filter: this.filter });
    },
    watch: {
        currentPage: function () {
            this.$store.dispatch(LIST_CLIENT, { page: this.currentPage, filter: this.filter });
        },
        filter: function () {
            this.$store.dispatch(LIST_CLIENT, { page: this.currentPage, filter: this.filter });
        }
    }
};
</script>
<style>
td {
    vertical-align: middle !important;
}
</style>
