<template>
    <div v-if="loading" class="d-flex flex-column justify-content-center align-items-center" style="height: 82vh !important">
        <img :src="loaderLogo" style="max-width: 30%; height: auto" alt="Academy Si2p Logo" />
        <div class="spinner-primary spinner-border" style="width: 3rem; height: 3rem" />
    </div>
    <div v-else-if="$store.getters.currentUser.role === 'Commercial'">
        <div class="d-flex justify-content-between align-items-center p-2 mt-4">
            <h2>Licences ouvertes récemment</h2>
            <button v-b-modal.licence-test class="btn btn-primary">
                <i class="fas fa-plus"></i>
                Licence test
            </button>
        </div>
        <hr />
        <div v-if="loadingData" class="d-flex flex-column justify-content-center align-items-center">
            <div class="spinner-primary spinner-border" style="width: 3rem; height: 3rem" />
            <p class="mt-3">Chargement des données...</p>
        </div>
        <div v-else>
            <p class="d-flex flex-column justify-content-center align-items-center" v-if="!dataLicence.data.length">
                Aucune licence trouvée.
            </p>
            <div v-else>
                <b-button-group style="display: flex; justify-content: flex-end">
                    <div style="margin-left: 5px; border-radius: 5px">
                        <b-button
                            style="margin-left: 5px"
                            v-for="(item, index) in filter"
                            :key="index"
                            @click="toggleFilter(index)"
                            :variant="item.active ? 'primary' : 'light'"
                        >
                            {{ item.title }}
                        </b-button>
                    </div>
                </b-button-group>
                <hr />
                <b-table
                    id="tableLicence"
                    :fields="fields"
                    :items="filteredLicences"
                    :per-page="perPage"
                    :current-page="currentPage"
                    class="mx-0 p-0 mb-5"
                    hover
                    no-border-collapse
                    responsive
                    show-empty
                    style="vertical-align: middle; text-align: center"
                >
                    <template #cell(code_client)="row">
                        <span>{{ row.item.customer.code }}</span>
                    </template>
                    <template #cell(client_name)="row">
                        <span>{{ row.item.customer.name }}</span>
                    </template>
                    <template #cell(created_at)="row">
                        <span>{{ formatDateWithTime(row.item.created_at) }}</span>
                    </template>
                    <template #cell(classe_name)="row">
                        <span>{{ row.item.classe_info.title }}</span>
                    </template>
                    <template #cell(allocated_to)="row">
                        <span>{{ row.item.user.lastname + ' ' + row.item.user.firstname }}</span>
                    </template>
                    <template #cell(email)="row">
                        <span>{{ row.item.user.email }}</span>
                    </template>
                    <template #cell(account_verified_at)="row">
                        <b-badge pill :variant="row.item.user.account_verified_at ? 'success' : 'danger'">{{
                            row.item.user.account_verified_at ? 'Oui' : 'Non'
                        }}</b-badge>
                    </template>
                    <template #cell(state)="row">
                        <span :class="stateClass(row.item.state.id)">{{ row.item.state.title }}</span>
                    </template>
                    <template #empty>
                        <div class="d-flex justify-content-center align-items-center">
                            <h3>Aucune licence trouvée.</h3>
                        </div>
                    </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="filteredLicences.length"
                    :per-page="perPage"
                    aria-controls="tableLicence"
                ></b-pagination>
            </div>
        </div>

        <modal-licence-test :token="token" @fetchLicences="fetchLicences()" />
    </div>
    <div v-else-if="$store.getters.currentUser.role === 'admin' && !loadingStats">
        <div class="containerCardStat d-flex my-5">
            <b-card class="cardStat" title="Nombre total de licences ouvertes:">
                <b-card-text style="font-size: x-large; text-align: center">
                    {{ statistics.totalLicenceTest }}
                </b-card-text>
            </b-card>
            <b-card class="cardStat" title="Top 3 des commerciaux:">
                <b-card-text v-for="(item, index) in statistics.top3Commercial" :key="index">
                    {{ index + 1 + ': ' + item.commercial + ` (${item.nbTest})` }}
                </b-card-text>
            </b-card>
            <!-- <b-card class="cardStat" title="Top 3 des clients:">
                <b-card-text v-for="(item, index) in statistics.top3Customer" :key="index">
                    {{ index + 1 + ': ' + item.customer + ` (${item.nbTest})` }}
                </b-card-text>
            </b-card> -->
            <b-card class="cardStat" title="Top 3 des classes:">
                <b-card-text v-for="(item, index) in statistics.top3Classe" :key="index">
                    {{ index + 1 + ': ' + item.classe + ` (${item.nbTest})` }}
                </b-card-text>
            </b-card>
        </div>
        <div class="containerFilter d-flex align-items-center mb-5">
            <div class="containerButtonsFilter">
                <div style="display: flex; align-items: center; flex-wrap: wrap">
                    <p style="margin-bottom: 0">Filtres</p>
                    <b-button
                        style="margin-left: 5px"
                        v-for="(item, index) in filterDate"
                        :key="index"
                        @click="toggleFilterAdmin(index)"
                        :variant="item.active ? 'primary' : 'light'"
                    >
                        {{ item.title }}
                    </b-button>
                </div>
                <div ref="boxPersoFilter" class="ml-3" style="display: none; transition: all 0.5s ease-in-out">
                    <div style="display: flex; flex-direction: column">
                        <label style="text-align: center; font-size: smaller" for="startDate">Date de début</label>
                        <input ref="inputStartDate" style="margin-right: 10px" type="date" name="startDate" id="starDate" />
                    </div>
                    <div style="display: flex; flex-direction: column">
                        <label style="text-align: center; font-size: smaller" for="endDate">Date de fin</label>
                        <input ref="inputEndDate" style="margin-right: 10px" type="date" name="endDate" id="endDate" />
                    </div>
                    <b-button @click="changePeriodeFilter()">Rechercher</b-button>
                </div>
            </div>

            <div style="display: flex; align-items: center">
                <CustomVSelect
                    style="margin-right: 5px"
                    id="classeSelect"
                    :options="selectOptionsClasses"
                    name="classe"
                    :required="false"
                    :state="true"
                    v-model="selectedClasse"
                    searchable
                    ref="classeSelect"
                    labelSearchPlaceholder="Sélectionner une classe"
                    labelNotFound="Aucun résultat pour"
                    labelTitle="Sélectionner une classe"
                />
                <CustomVSelect
                    style="margin-right: 5px"
                    id="commercialSelect"
                    :options="selectOptionsCommercials"
                    name="commercial"
                    :required="false"
                    :state="true"
                    v-model="selectedCommercial"
                    searchable
                    ref="commercialSelect"
                    labelSearchPlaceholder="Sélectionner un commercial"
                    labelNotFound="Aucun résultat pour"
                    labelTitle="Sélectionner un commercial"
                />
                <b-button @click="resetSelected()">Réinitialiser</b-button>
            </div>
        </div>
        <div style="height: 110vh" class="d-flex flex-column">
            <div style="width: 100%; height: 50vh; margin-bottom: 20px">
                <CamembertChart
                    title="Nombre de licences ouvertes par classe"
                    :data="filteredStatistics.licencePerCourses"
                    height="50vh"
                    width="100%"
                />
            </div>
            <div style="width: 100%; height: 50vh">
                <SortChart
                    title="Licences ouvertes par commercial"
                    :data="filteredStatistics.licencePerCommercial"
                    height="50vh"
                    width="100%"
                />
            </div>
        </div>
    </div>
    <div v-else>
        <div class="d-flex justify-content-center align-items-center m-5">
            <div class="spinner-primary spinner-border" style="width: 2rem; height: 2rem; margin-right: 10px" />
            <p class="mt-3">Chargement des données...</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_CLASSEUSERS } from '../../../core/services/store/api/classe_users.service';
import { GET_TEST_PER_TOTAL } from '@/core/services/store/api/classe_users.service';
import axios from 'axios';
import moment from 'moment';
import ModalLicenceTest from '../../../components/home/modalLicenceTest.vue';
import CamembertChart from '../../../components/graphiqueChart/camembertChart.vue';
import SortChart from '../../../components/graphiqueChart/sortChart.vue';
import VSelect from '@alfsnd/vue-bootstrap-select';

export default {
    name: 'admin_home',
    components: {
        ModalLicenceTest,
        CamembertChart,
        SortChart,
        CustomVSelect: VSelect
    },
    data() {
        return {
            seriesPie: [],
            chartPieOptions: {
                labels: [],
                legend: {
                    show: true
                    // position: 'bottom',
                    // horizontalAlign: 'left'
                }
            },
            seriesBar: [
                {
                    name: 'Licences',
                    data: []
                }
            ],
            chartBarOptions: {
                labels: []
            },
            dataLicence: [],
            dataLicenceFilters: [],
            loading: true,
            loadingData: true,
            loadingStats: true,
            token: '',
            fields: [
                { key: 'code_client', label: 'Code client', thStyle: 'width: 10%', tdStyle: 'width: 10%', tdClass: 'align-middle' },
                { key: 'client_name', label: 'Nom du client' },
                { key: 'classe_name', label: 'Nom de la classe' },
                { key: 'allocated_to', label: 'Attribué à' },
                { key: 'email', label: 'Adresse mail' },
                { key: 'account_verified_at', label: 'Compte vérifié' },
                { key: 'state', label: 'Etat', sortable: false },
                { key: 'created_at', label: 'Créé le', sortable: true }
            ],
            filter: [
                { title: 'Non commencé', active: true },
                { title: 'En cours', active: true },
                { title: 'Terminé', active: true }
            ],

            filterDate: [
                { title: 'Tout', active: true },
                { title: 'Jour', active: false },
                { title: 'Semaine', active: false },
                { title: 'Mois', active: false },
                { title: 'Année', active: false },
                { title: 'Personnalisé', active: false }
            ],
            filterPeriode: {
                startDate: null,
                endDate: null
            },

            currentPage: 1,
            perPage: 10,
            statistics: {
                classes: [],
                commercials: [],
                customers: [],
                licences: [],
                top3Classe: [],
                top3Commercial: [],
                top3Customer: [],
                totalLicenceTest: 0
            },
            filteredStatistics: {
                licencePerCourses: [],
                licencePerCommercial: {
                    commercials: [],
                    values: []
                }
            },
            selectOptionsCommercials: [],
            selectedCommercial: null,
            selectOptionsClasses: [],
            selectedClasse: null
        };
    },
    computed: {
        ...mapGetters(['layoutConfig']),
        loaderLogo() {
            return `${process.env.BASE_URL}${this.layoutConfig('loader.logo')}`;
        },
        filteredLicences() {
            return this.dataLicence.data.filter((item) => this.filter.some((f) => f.active && f.title === item.state.title));
        }
    },
    mounted() {
        if (this.$store.getters.currentUser.role === 'admin') {
            // if (this.$store.getters.currentUser.role === 'Commercial') {
            this.getTestPerTotal();
        } else {
            this.fetchToken();
            this.fetchLicences();
        }
        this.loading = false;
    },
    methods: {
        isMobile() {
            return window.screen.availWidth > 992 ? false : true;
        },
        getTestPerTotal() {
            this.$store.dispatch(GET_TEST_PER_TOTAL).then((response) => {
                this.statistics = response.data;
                this.selectOptionsCommercials = this.statistics.commercials.map((commercial) => {
                    return {
                        value: commercial.id,
                        text: commercial.lastname + ' ' + commercial.firstname
                    };
                });
                this.selectOptionsClasses = this.statistics.classes.map((classe) => {
                    return {
                        value: classe.id,
                        text: classe.title
                    };
                });
                this.setStatistics();
            });
        },
        setStatistics() {
            this.statistics.licences.forEach((licence) => {
                licence.commercial_id = this.statistics.commercials.filter((commercial) => commercial.id === licence.commercial_id)[0];
                licence.classe_id = this.statistics.classes.filter((classe) => classe.id === licence.classe_id)[0];
                licence.customer_id = this.statistics.customers.filter((customer) => customer.id === licence.customer_id)[0];
            });

            this.statLicencePerCourses();
            this.statLicenceOpenPerCommercial();
        },
        statLicencePerCourses() {
            this.filteredStatistics.licencePerCourses = [];
            this.statistics.classes.forEach((classe) => {
                let countLicence = 0;
                this.statistics.licences.forEach((licence) => {
                    let filter = false;
                    if (!this.filterPeriode.startDate && !this.filterPeriode.endDate) {
                        filter = true;
                    } else if (this.filterPeriode.startDate === this.filterPeriode.endDate) {
                        if (moment(licence.created_at).isSame(moment(this.filterPeriode.startDate), 'day')) {
                            filter = true;
                        }
                    } else {
                        if (
                            moment(licence.created_at).isSameOrAfter(moment(this.filterPeriode.startDate), 'day') &&
                            moment(licence.created_at).isSameOrBefore(moment(this.filterPeriode.endDate), 'day')
                        ) {
                            filter = true;
                        }
                    }
                    if (
                        licence.classe_id.id === classe.id &&
                        filter &&
                        (!this.selectedCommercial || licence.commercial_id.id === this.selectedCommercial.value) &&
                        (!this.selectedClasse || licence.classe_id.id === this.selectedClasse.value)
                    ) {
                        countLicence++;
                    }
                });
                if (countLicence > 0) {
                    this.filteredStatistics.licencePerCourses.push({ name: classe.title, value: countLicence });
                }
            });
        },
        statLicenceOpenPerCommercial() {
            this.filteredStatistics.licencePerCommercial.commercials = [];
            this.filteredStatistics.licencePerCommercial.values = [];
            this.statistics.commercials.forEach((commercial) => {
                let countLicence = 0;
                this.statistics.licences.forEach((licence) => {
                    let filter = false;
                    if (!this.filterPeriode.startDate && !this.filterPeriode.endDate) {
                        filter = true;
                    } else if (this.filterPeriode.startDate === this.filterPeriode.endDate) {
                        if (moment(licence.created_at).isSame(moment(this.filterPeriode.startDate), 'day')) {
                            filter = true;
                        }
                    } else {
                        if (
                            moment(licence.created_at).isSameOrAfter(moment(this.filterPeriode.startDate), 'day') &&
                            moment(licence.created_at).isSameOrBefore(moment(this.filterPeriode.endDate), 'day')
                        ) {
                            filter = true;
                        }
                    }
                    if (
                        licence.commercial_id.id === commercial.id &&
                        filter &&
                        (!this.selectedClasse || licence.classe_id.id === this.selectedClasse.value) &&
                        (!this.selectedCommercial || licence.commercial_id.id === this.selectedCommercial.value)
                    ) {
                        countLicence++;
                    }
                });
                if (countLicence > 0) {
                    this.filteredStatistics.licencePerCommercial.commercials.push(commercial.lastname + ' ' + commercial.firstname);
                    this.filteredStatistics.licencePerCommercial.values.push(countLicence);
                }
            });
        },
        async fetchToken() {
            try {
                const { data } = await axios.post(`${process.env.VUE_APP_API_ORION_URL}/auth/plateforme`, {
                    email: this.$store.getters.currentUser.email,
                    password: this.$store.getters.currentUser.password
                });
                this.token = data.authorisation.token;
            } catch (error) {
                // console.error('Failed to fetch token:', error);
            }
        },
        async fetchLicences() {
            this.$store.dispatch(GET_CLASSEUSERS, { commercial_id: this.$store.getters.currentUser.id }).then((response) => {
                this.dataLicence = response.data;
            });
        },
        toggleFilter(index) {
            this.filter[index].active = !this.filter[index].active;
        },
        toggleFilterAdmin(index) {
            this.filterDate.forEach((item, i) => {
                if (i === index) {
                    item.active = true;
                    switch (index) {
                        case 0:
                            this.clearFilterPeriode();
                            break;
                        case 1:
                            this.setFilterPeriode(moment().startOf('day'), moment().endOf('day'));
                            break;
                        case 2:
                            this.setFilterPeriode(moment().startOf('week'), moment().endOf('week'));
                            break;
                        case 3:
                            this.setFilterPeriode(moment().startOf('month'), moment().endOf('month'));
                            break;
                        case 4:
                            this.setFilterPeriode(moment().startOf('year'), moment().endOf('year'));
                            break;
                        case 5:
                            //remove style display none and add style display flex
                            this.$refs.boxPersoFilter.style.display = 'flex';
                            break;
                    }
                    this.statLicencePerCourses();
                    this.statLicenceOpenPerCommercial();
                } else {
                    item.active = false;
                }
            });
        },
        clearFilterPeriode() {
            this.filterPeriode.startDate = null;
            this.filterPeriode.endDate = null;
            this.$refs.boxPersoFilter.style.display = 'none';
            this.$refs.inputStartDate.value = '';
            this.$refs.inputEndDate.value = '';
        },
        resetSelected() {
            this.selectedCommercial = null;
            this.selectedClasse = null;
            this.statLicencePerCourses();
            this.statLicenceOpenPerCommercial();
        },
        setFilterPeriode(startDate, endDate) {
            this.$refs.boxPersoFilter.style.display = 'none';
            this.filterPeriode.startDate = startDate;
            this.filterPeriode.endDate = endDate;
            this.$refs.inputStartDate.value = '';
            this.$refs.inputEndDate.value = '';
        },
        changePeriodeFilter() {
            this.filterPeriode.startDate = document.getElementById('starDate').value;
            this.filterPeriode.endDate = document.getElementById('endDate').value;

            this.statLicencePerCourses();
            this.statLicenceOpenPerCommercial();
        },
        formatDateWithTime(value) {
            return value ? moment(String(value)).format('DD/MM/YYYY') : '';
        },
        stateClass(stateId) {
            return stateId === 2 ? 'text-primary' : stateId === 3 ? 'text-success' : '';
        }
    },
    watch: {
        dataLicence() {
            this.loadingData = false;
            this.dataLicenceFilters = this.dataLicence.data;
        },
        statistics() {
            this.loadingStats = false;
        },
        selectedCommercial() {
            if (this.selectedCommercial) {
                this.selectedClasse = null;
            }
            this.statLicencePerCourses();
            this.statLicenceOpenPerCommercial();
        },
        selectedClasse() {
            if (this.selectedClasse) {
                this.selectedCommercial = null;
            }
            this.statLicencePerCourses();
            this.statLicenceOpenPerCommercial();
        }
    }
};
</script>

<style scoped>
.containerCardStat {
    height: 22%;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
        justify-content: center;
    }
}

.cardStat {
    margin-bottom: 20px;
    width: 30%;
    /* min-width: 200px; */
    /* max-width: 325px; */
    margin-right: 10px;

    @media screen and (max-width: 800px) {
        margin-right: 0;
        width: 100%;
    }
}

.containerFilter {
    /* flex-direction: row-reverse; */
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start !important;
    }
}

.containerButtonsFilter {
    display: flex;

    @media screen and (max-width: 1200px) {
        flex-direction: column !important;
        align-items: flex-start !important;
        margin-bottom: 10px;
    }
}
</style>
