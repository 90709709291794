<template>
    <!-- Modal -->
    <b-modal id="licence-test" v-model="showModal" title="Ouverture d'une licence test" @hide="resetStep()" size="lg">
        <template v-if="step === 1">
            <form class="d-flex" @submit.prevent="searchClients">
                <input
                    v-model="filterName"
                    type="text"
                    class="form-control"
                    placeholder="Rechercher un client via son code (3 caractères minimum)"
                />
                <b-button type="submit" class="ml-3" variant="primary">
                    <div class="d-flex align-items-center">
                        <b-spinner small v-if="loadingSearch" class="mr-2" />
                        Rechercher
                    </div>
                </b-button>
            </form>
            <b-list-group style="max-height: 35vh; overflow: scroll" class="mt-3" v-if="!loadingSearch">
                <b-list-group-item
                    v-for="option in options"
                    :key="option.value"
                    @click="selectedOption = option.value"
                    :active="option.value === selectedOption"
                    :disabled="option.value === '0'"
                >
                    {{ option.text }}
                </b-list-group-item>
            </b-list-group>
        </template>
        <template v-else-if="step === 2">
            <div v-if="loadingNextStep">Importation du client sur la plateforme</div>
            <div v-else>
                <b-button v-if="!createGestionnaire" variant="primary" @click="createGestionnaire = true" :disabled="!customerList.length"
                    >Créer un gestionnaire</b-button
                >
                <b-button v-if="createGestionnaire" variant="danger" @click="createGestionnaire = false">Annuler</b-button>
                <div v-if="loadingGestionnaires" class="w-100 d-flex justify-content-center">
                    <b-spinner />
                </div>
                <CreateTrainee
                    v-else-if="createGestionnaire"
                    :gestionnaire="true"
                    :customerList="customerList"
                    @refresh-table="
                        fetchGestionnaires();
                        createGestionnaire = false;
                    "
                />
                <template v-else>
                    <div class="mt-3">
                        <p>Cliquez sur les gestionnaires pour les sélectionner (3 maximum)</p>
                        <b-list-group style="max-height: 35vh; overflow: scroll">
                            <b-list-group-item
                                v-for="gestionnaire in gestionnaires"
                                :key="gestionnaire.id"
                                @click="toggleGestionnaireSelection(gestionnaire)"
                                :active="selectedGestionnaires.includes(gestionnaire)"
                                :disabled="gestionnaire.id == 0"
                            >
                                {{ gestionnaire.lastname }} {{ gestionnaire.firstname }}
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </template>
            </div>
        </template>
        <template v-else-if="step === 3">
            <b-form-group label="Filtrer parmi les classes" label-for="filterClasses">
                <b-form-input id="filterClasses" v-model="filterClasse" placeholder="Rechercher une classe" class="mb-2" />
            </b-form-group>
            <div v-if="loadingClasses" class="w-100 d-flex justify-content-center">
                <b-spinner />
            </div>
            <b-list-group style="max-height: 35vh; overflow: scroll" v-if="!loadingClasses && classesCatalogue.length">
                <b-list-group-item
                    v-for="classe in filteredClasses"
                    :key="classe.id"
                    @click="toggleClasseSelection(classe)"
                    :active="selectedClasses.includes(classe)"
                >
                    {{ classe.title }}
                </b-list-group-item>
            </b-list-group>
            <p v-else-if="!loadingClasses && !classesCatalogue.length" class="text-center">Aucune classe trouvée.</p>
        </template>
        <template #modal-footer="{}">
            <b-button v-if="step !== 1" @click="prevStep()" variant="primary" :disabled="!selectedOption || loadingNextStep"
                >Précédent</b-button
            >
            <b-button
                v-if="step !== 3"
                @click="nextStep()"
                variant="primary"
                :disabled="(step === 1 && !clientSelected) || (step === 2 && !selectedGestionnaires.length) || loadingNextStep"
            >
                {{ !loadingNextStep ? 'Suivant' : 'Chargement...' }}
            </b-button>
            <b-button v-if="step === 3" @click="openLicenceTest()" variant="primary" :disabled="!selectedClasses.length || sending">
                <div class="d-flex align-items-center">
                    <b-spinner small v-if="sending" class="mr-2" />
                    Ouvrir les licences
                </div>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import axios from 'axios';

import CreateTrainee from '@/view/pages/admin/trainee/CreateTrainee';

import { LIST_CLIENT_GROUP } from '@/core/services/store/api/client.service';
import { LIST_CUSTOMER_TRAINEE_BY_ROLE } from '@/core/services/store/api/trainee.service';
import { LIST_CATALOG_CLASSES } from '@/core/services/store/api/classe.service';
import { OPEN_LICENCES_TEST } from '@/core/services/store/api/classe_users.service';

export default {
    name: 'ModalLicenceTest',
    components: {
        CreateTrainee
    },
    data() {
        return {
            showModal: false,
            filterName: '',
            loadingSearch: false,
            options: [],
            clients: [],
            step: 1,
            selectedOption: '',
            filterClasse: '',
            loadingNextStep: false,
            inputValue: '',
            clientSelected: '',
            loadingGestionnaires: false,
            gestionnaires: [],
            selectedGestionnaires: [],
            createGestionnaire: false,
            customerList: [],
            loadingClasses: false,
            classesCatalogue: [],
            filterClasses: '',
            selectedClasses: [],
            sending: false,
            prev: false,
            next: false,
            clientUpdated: {}
        };
    },
    props: {
        token: {
            type: String,
            required: true
        }
    },

    methods: {
        async searchClients() {
            if (this.filterName.length < 3) return;
            this.loadingSearch = true;
            this.selectedOption = '';
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_API_ORION_URL}/clients/all?filterName=${this.filterName}`, {
                    headers: { Authorization: `Bearer ${this.token}` }
                });
                this.clients = data;
                this.options = data.length
                    ? data.map((client) => ({
                          value: `${client.cli_id}-${client.cli_plateforme}`,
                          text: `${client.cli_code} - ${client.cli_nom}`
                      }))
                    : [{ value: '0', text: 'Aucun résultat trouvé' }];
                this.loadingSearch = false;
            } catch (error) {
                //console.log(error)
            }
        },
        resetStep() {
            this.step = 1;
            this.selectedOption = '';
            this.filterName = '';
            this.options = [];
            this.loadingSearch = false;
            this.loadingGestionnaires = false;
            this.gestionnaires = [];
            this.selectedGestionnaires = [];
            this.createGestionnaire = false;
            this.customerList = [];
            this.classesCatalogue = [];
            this.selectedClasses = [];
            this.filterClasses = '';
        },
        nextStep() {
            this.next = true;
            if (this.step === 1 && !this.selectedOption) return;
            this.step = this.step < 3 ? this.step + 1 : 1;
            if (this.step === 1) this.showModal = false;
        },
        prevStep() {
            this.prev = true;
            this.step = this.step > 1 ? this.step - 1 : 1;
        },
        toggleGestionnaireSelection(gestionnaire) {
            const index = this.selectedGestionnaires.indexOf(gestionnaire);
            if (index === -1) {
                if (this.selectedGestionnaires.length < 3) {
                    this.selectedGestionnaires.push(gestionnaire);
                } else {
                    this.$bvToast.toast('Vous ne pouvez pas sélectionner plus de 3 gestionnaires', {
                        title: 'Attention',
                        variant: 'warning',
                        autoHideDelay: 5000
                    });
                }
            } else {
                this.selectedGestionnaires.splice(index, 1);
            }
        },
        toggleClasseSelection(classe) {
            const index = this.selectedClasses.indexOf(classe);
            if (index === -1) {
                // if (this.selectedClasses.length < 3) {
                //     this.selectedClasses.push(classe);
                // } else {
                //     this.$bvToast.toast('Vous ne pouvez pas sélectionner plus de 3 classes', {
                //         title: 'Attention',
                //         variant: 'warning',
                //         autoHideDelay: 5000
                //     });
                // }
                this.selectedClasses.push(classe);
            } else {
                this.selectedClasses.splice(index, 1);
            }
        },
        async fetchGestionnaires() {
            this.loadingGestionnaires = true;
            this.gestionnaires = [];
            this.selectedGestionnaires = [];
            try {
                const { data } = await this.$store.dispatch(LIST_CUSTOMER_TRAINEE_BY_ROLE, {
                    role_id: 2,
                    customer_id: this.clientUpdated.id
                });
                this.gestionnaires = data.length ? data : [{ id: 0, lastname: 'Aucun', firstname: 'gestionnaire' }];
                this.loadingGestionnaires = false;
            } catch (error) {
                this.loadingGestionnaires = false;
            }
        },
        async fetchCustomerList() {
            try {
                const { data } = await this.$store.dispatch(LIST_CLIENT_GROUP, {
                    customer_id: this.clientUpdated.id
                });
                this.customerList = data.map((customer) => ({
                    text: `${customer.name} (${customer.code})`,
                    value: customer.id
                }));
            } catch (error) {
                // console.error(error);
            }
        },
        async fetchClassesCatalogue() {
            this.loadingClasses = true;
            try {
                const { data } = await this.$store.dispatch(LIST_CATALOG_CLASSES, { user_id: null });
                this.classesCatalogue = data;
                this.loadingClasses = false;
            } catch (error) {
                this.loadingClasses = false;
            }
        },
        async reloadLicences() {
            this.$emit('fetchLicences');
        },
        openLicenceTest() {
            if (!this.selectedClasses.length) {
                return;
            }
            this.sending = true;
            try {
                this.$store
                    .dispatch(OPEN_LICENCES_TEST, {
                        customer_id: this.clientUpdated.id,
                        gestionnaires: this.selectedGestionnaires.map((gestionnaire) => gestionnaire.id),
                        classes: this.selectedClasses.map((classe) => classe.id)
                    })
                    .then((data) => {
                        data.data.errors.forEach((error) => {
                            this.$bvToast.toast(error, {
                                title: 'Erreur',
                                variant: 'danger',
                                autoHideDelay: 15000
                            });
                        });
                        data.data.success.forEach((success) => {
                            this.$bvToast.toast(success, {
                                title: 'Succès',
                                variant: 'success',
                                autoHideDelay: 15000
                            });
                        });
                        this.reloadLicences();
                        this.sending = false;
                        this.showModal = false;
                    });
            } catch (error) {
                // console.error(error);
            }
        },
        async fetchImportClient() {
            this.loadingNextStep = true;
            try {
                const { data } = await axios.post(
                    `${process.env.VUE_APP_API_URL}/customers/createOrUpdateGroup`,
                    { id_cli: this.clientSelected.cli_id, id_plateforme: this.clientSelected.cli_plateforme },
                    {}
                );

                this.clientUpdated = data.dataClientUpdated;

                this.fetchGestionnaires();
                this.fetchCustomerList();
            } catch (error) {
                this.loadingNextStep = false;
                this.clientUpdated = {};
                this.step = 1;
                this.$bvToast.toast("Une erreur est survenue lors de l'importation du client: " + error.response.data.error, {
                    title: 'Erreur',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    },
    computed: {
        filteredClasses() {
            return this.classesCatalogue.filter((classe) => classe.title.toLowerCase().includes(this.filterClasse.toLowerCase()));
        }
    },
    watch: {
        step: async function () {
            if (this.step === 2) {
                this.next && (await this.fetchImportClient());
                this.loadingNextStep = false;
            } else if (this.step === 3) {
                this.fetchClassesCatalogue();
            }
            this.prev = false;
            this.next = false;
        },
        selectedOption() {
            this.clientSelected = this.clients.filter((client) => client.cli_id == this.selectedOption.split('-')[0]);
            this.clientSelected = this.clientSelected[0];
        }
    }
};
</script>

<style scoped>
/* Vos styles ici */
</style>
